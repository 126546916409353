import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

class WorkTitle extends React.Component {
  // const { data } = this.props

  render() {

    return (
      <div className="columns is-multiline">
        </div>
  )}
}

export default WorkTitle